import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { desktopCSS } from '@measures/responsive'
import useHasInvertedColors from '@hooks/useHasInvertedColors'
import useShowRedCatchwordVariant from '@hooks/useShowRedCatchwordVariant'

export interface CatchwordProps {
  children?: ReactNode
  hasPlusLogo: boolean
}

export interface InvertedColorsStyledProps {
  hasInvertedColors: boolean
}

export interface ShowRedCatchwordVariantStyledProps {
  showRedCatchwordVariant: boolean
}

export type StyledCatchwordProps = Pick<CatchwordProps, 'hasPlusLogo'> &
  InvertedColorsStyledProps &
  ShowRedCatchwordVariantStyledProps

const StyledCatchword = styled.div<StyledCatchwordProps>`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        tertiary: { grey400: grey400Color, grey800: grey800Color },
        primary: { blickRed },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      utils: { ellipsis },
    },
    hasPlusLogo,
    hasInvertedColors,
    showRedCatchwordVariant,
  }) => {
    const color = hasInvertedColors
      ? grey400Color
      : showRedCatchwordVariant
        ? blickRed
        : grey800Color
    return css`
      ${small1SubheadingCSS};
      color: ${color};
      margin-bottom: ${spacing4};
      ${ellipsis('100%', 1)};
      display: block;
      ${desktopCSS(css`
        ${ellipsis('100%', 2)};
        ${hasPlusLogo &&
        css`
          line-height: 1.3;
        `};
      `)}
    `
  }}
`

const Catchword: FunctionComponent<CatchwordProps> = ({
  children,
  hasPlusLogo,
}) => {
  const hasInvertedColors = useHasInvertedColors()
  const showRedCatchwordsVariant = useShowRedCatchwordVariant()

  return (
    <StyledCatchword
      hasPlusLogo={hasPlusLogo}
      hasInvertedColors={hasInvertedColors}
      showRedCatchwordVariant={showRedCatchwordsVariant}>
      {children}
    </StyledCatchword>
  )
}

export default Catchword
