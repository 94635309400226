import HockeySoccerSportsFooter from '@components/SportsFooter/HockeySoccer'
import SportLabelContent from '@components/SportsFooter/SportLabelContent'
import { transformDataForSportsComponents } from '@components/SportsFooter/utils'
import BlickPlusLogo from '@components/TeaserListItem/BlickPlusLogo'
import Catchword from '@components/TeaserListItem/Catchword'
import CommercialLabel from '@components/TeaserListItem/CommercialLabel'
import Date from '@components/TeaserListItem/Date'
import Label from '@components/TeaserListItem/Label'
import Lead from '@components/TeaserListItem/Lead'
import PromoLabel from '@components/TeaserListItem/PromoLabel'
import TeaserImage from '@components/TeaserListItem/TeaserImage'
import TeaserWrapper from '@components/TeaserListItem/TeaserWrapper'
import TextAndTimestampWrapper from '@components/TeaserListItem/TextAndTimestampWrapper'
import Title from '@components/TeaserListItem/Title'
import { ListItemTeaserAPIProps } from '@components/TeaserListItem/types'
import VideoLabel from '@components/VideoLabel'
import useSportsEvent from '@hooks/useSportsEvent'
import { desktopCSS } from '@measures/responsive'
import { LIVE_EVENT_DATA_SPORT_TYPES } from '@utils/formatters/common'
import { LiveEventDataSportTypes } from '@utils/formatters/liveEvent'
import { FunctionComponent, useCallback } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import useIsOnSearchPage from '@hooks/useIsOnSearchPage'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import useViewportTracking from '@hooks/useViewportTracking'
import { stripHtml } from '@hooks/useTracking/utils'
import { SearchResultsData } from './Search/types'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useRefDelegate from '@hooks/useRefDelegate'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import useShowRedCatchwordVariant from '@hooks/useShowRedCatchwordVariant'

interface StyledTeaserImageProps {
  hasBorder: boolean
}

const StyledTeaserImage = styled(TeaserImage)<StyledTeaserImageProps>`
  ${({
    theme: {
      spacing: { spacing8 },
      color: {
        primary: { primary02: primary02Color },
      },
    },
    hasBorder,
  }) => css`
    ${hasBorder &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: ${spacing8};
        right: ${spacing8};
        bottom: ${spacing8};
        left: ${spacing8};
        border: 1px solid ${primary02Color};
        z-index: 1;
      }
    `}
  `}
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing16, spacing12 },
    },
  }) => css`
    position: absolute;
    z-index: 1;
    bottom: ${spacing12};
    left: ${spacing12};
    ${desktopCSS(css`
      bottom: ${spacing16};
      left: ${spacing16};
    `)};
  `}
`

const StyledSportsFooter = styled(HockeySoccerSportsFooter)`
  position: absolute;
  bottom: 0;
  left: 0;
`

const ListItemTeaser: FunctionComponent<ListItemTeaserAPIProps> = ({
  accentColor,
  catchword,
  image,
  isPlus,
  labelText,
  lastModifiedDate,
  lead,
  link,
  commercial,
  commercialType,
  targetContentType,
  title,
  video,
  matchId,
  typeOfSport,
  articleId,
  contentOrigin,
  track,
}) => {
  const {
    color: {
      secondary: { greenAccessible },
    },
  } = useTheme()

  const isValidSport =
    !!matchId &&
    !!typeOfSport &&
    (
      [
        LIVE_EVENT_DATA_SPORT_TYPES.SOCCER,
        LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY,
      ] as LiveEventDataSportTypes[]
    ).includes(typeOfSport)

  const { data: sportsEventData } = useSportsEvent({
    matchId: isValidSport ? matchId : '',
    typeOfSport: isValidSport ? typeOfSport : 'NO_SPORT',
    shouldRefetch: true,
  })

  const sportsData = sportsEventData
    ? transformDataForSportsComponents(sportsEventData)
    : undefined

  const shouldShowSportsComponents =
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.SOCCER ||
    sportsData?.typeOfSport === LIVE_EVENT_DATA_SPORT_TYPES.HOCKEY

  const isCommercial = !!commercialType
  const isOnSearchPage = useIsOnSearchPage()
  const showCatchword = !!isPlus || !!catchword
  const showRedCatchwordsVariant = useShowRedCatchwordVariant()

  const hasLabelText = !isCommercial && !!labelText

  const labelColor = sportsData?.isLive
    ? undefined
    : isValidSport
      ? greenAccessible
      : accentColor

  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_impression',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'impression',
      is_branded: !!contentOrigin,
      link_url: link.href,
      teaser_type: showRedCatchwordsVariant ? 'variantB' : 'original',
    }),
    [
      articleId,
      title,
      video,
      contentOrigin,
      link.href,
      showRedCatchwordsVariant,
    ]
  )

  const onPromoImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'contentcommerce_impression',
      eventCategory: 'BrandStudio',
      eventAction: 'Impression / Brandstudio',
    }),
    []
  )

  const onClick = useCallback(
    () => ({
      event: 'teaser_click',
      eventCategory: video ? 'video_teaser' : 'article_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'click',
      id: articleId,
      is_branded: !!contentOrigin,
      link_url: link.href,
      teaser_type: showRedCatchwordsVariant ? 'variantB' : 'original',
    }),
    [
      articleId,
      title,
      video,
      contentOrigin,
      link.href,
      showRedCatchwordsVariant,
    ]
  )

  const onSearchResultClick = useCallback<TrackingFnType>(
    ({ queryClient }) => {
      const { totalElements, totalPages, searchResultRange, searchTerm } =
        queryClient.getQueryData<SearchResultsData>(['searchResults']) ?? {}

      return {
        event: 'search_result_click',
        eventCategory: 'search',
        eventLabel: articleId,
        eventAction: 'result_click',

        total_elements: totalElements,
        total_pages: totalPages,
        q_search_result_range: searchResultRange,
        search_term: searchTerm?.toLowerCase(),
      }
    },
    [articleId]
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnPromoImpression = useTracking(onPromoImpression)
  const trackedOnClick = useTracking(onClick)
  const trackedOnSearchResultClick = useTracking(onSearchResultClick)

  const finalOnImpression = useCallback(() => {
    trackedOnImpression()
    if (commercialType) {
      trackedOnPromoImpression()
    }
  }, [commercialType, trackedOnImpression, trackedOnPromoImpression])

  const isABtest = !!catchword
  const shouldBeTracked = !!contentOrigin || !!track || isABtest

  const finalOnClick = useCallback(() => {
    if (isOnSearchPage) {
      trackedOnSearchResultClick()
    }
    if (shouldBeTracked) {
      trackedOnClick()
    }
  }, [
    isOnSearchPage,
    shouldBeTracked,
    trackedOnSearchResultClick,
    trackedOnClick,
  ])

  const clickableProps = {
    onClick: finalOnClick,
    ...link,
  }
  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: clickableProps.href,
    }),
    useViewportTracking({
      onImpression: finalOnImpression,
      track: shouldBeTracked,
    })
  )
  const hasBorder = isCommercial && commercialType !== 'editorial'

  return (
    <TeaserWrapper
      clickableProps={clickableProps}
      isOnSearchPage={isOnSearchPage}
      ref={ref}>
      <TextAndTimestampWrapper>
        {isCommercial && commercial?.label && (
          <CommercialLabel
            commercialType={commercialType}
            commercialLogo={commercial.logo}>
            {commercial?.label}
          </CommercialLabel>
        )}
        {(hasLabelText || isValidSport) && (
          <Label labelColor={labelColor}>
            {shouldShowSportsComponents ? (
              <SportLabelContent sportsData={sportsData} />
            ) : hasLabelText ? (
              labelText
            ) : null}
          </Label>
        )}
        {showCatchword && (
          <Catchword>
            {isPlus && <BlickPlusLogo />}
            {catchword}
          </Catchword>
        )}
        <Title>{title}</Title>
        {lead ? <Lead>{lead}</Lead> : null}
        {lastModifiedDate ? <Date>{lastModifiedDate}</Date> : null}
      </TextAndTimestampWrapper>
      <StyledTeaserImage {...image} hasBorder={hasBorder}>
        {!!video && (
          <TeaserAnimatedPreview video={video} articleUrl={link?.href} />
        )}
        {commercialType === 'promo' && !!commercial?.label ? (
          <PromoLabel>{commercial.label}</PromoLabel>
        ) : null}
        {!!video && (
          <StyledVideoLabel
            duration={video.duration}
            targetContentType={targetContentType}
          />
        )}
        {shouldShowSportsComponents && (
          <StyledSportsFooter sportsData={sportsData} viewType="compact" />
        )}
      </StyledTeaserImage>
    </TeaserWrapper>
  )
}

const widget = {
  kind: ['teaser-list-item'],
  component: ListItemTeaser,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
