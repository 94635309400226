import { desktopCSS, mobileAndTabletCSS } from '@measures/responsive'
import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import useShowRedCatchwordVariant from '@hooks/useShowRedCatchwordVariant'
interface CatchwordProps {
  children: ReactNode
}

interface StyledCatchwordProps {
  showRedCatchwordsVariant: boolean
}

const StyledCatchword = styled.div<StyledCatchwordProps>`
  ${({
    theme: {
      spacing: { spacing8 },
      typography: {
        subheads: {
          small1: { bundledCSS: subheadSmall1 },
        },
      },
      color: {
        tertiary: { grey800 },
        primary: { blickRed },
      },
      utils: { ellipsis },
    },
    showRedCatchwordsVariant,
  }) => {
    return css`
      margin-bottom: ${spacing8};

      //! Needed because "ellipsis()" returns "display: inline-block;"
      //! when the second argument is 1.
      display: block;
      color: ${showRedCatchwordsVariant ? blickRed : grey800};

      ${subheadSmall1};

      ${desktopCSS(css`
        ${ellipsis('100%', 1)};
      `)}

      ${mobileAndTabletCSS(css`
        ${ellipsis('100%', 2)};
      `)}
    `
  }}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => {
  const showRedCatchwordsVariant = useShowRedCatchwordVariant()
  return (
    <StyledCatchword showRedCatchwordsVariant={showRedCatchwordsVariant}>
      {children}
    </StyledCatchword>
  )
}

export default Catchword
