import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import useShowRedCatchwordVariant from '@hooks/useShowRedCatchwordVariant'

export interface CatchwordProps {
  children?: ReactNode
}

interface StyledCatchwordProps {
  showRedCatchwordVariant: boolean
}

const StyledCatchword = styled.div<StyledCatchwordProps>`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        tertiary: { grey800: grey800Color },
        primary: { blickRed },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      utils: { ellipsis },
    },
    showRedCatchwordVariant,
  }) => css`
    ${small1SubheadingCSS};
    color: ${showRedCatchwordVariant ? blickRed : grey800Color};
    margin-bottom: ${spacing4};
    ${ellipsis('100%', 1)};
    display: block;
  `}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => {
  const showRedCatchwordsVariant = useShowRedCatchwordVariant()
  return (
    <StyledCatchword showRedCatchwordVariant={showRedCatchwordsVariant}>
      {children}
    </StyledCatchword>
  )
}

export default Catchword
