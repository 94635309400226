import { useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

export type LoggedOutUser = null | undefined

export type Gender = '' | 'male' | 'female' | 'none' | null

export interface NonLoggedOutUser extends Record<string, unknown> {
  email: string
  sub: string
  lang: string
  s: {
    blick: string
    romandie: string
  }
}

export interface EmailOnlyUser extends NonLoggedOutUser {
  email_only: true
}

export interface FullUser extends NonLoggedOutUser {
  first_name: string
  last_name: string
  gender: Gender
  status_id: number
  email_only: false
  initials: string
}

export type User = LoggedOutUser | FullUser | EmailOnlyUser

const useUser = () => {
  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const [userData, setUserData] = useState<User>(
    () => queryClient.getQueryData<User>(['user']) ?? null
  )

  const unsubscribeFnRef = useRef<() => void>(undefined)

  const updateUserData = useCallback(
    (args: any) => {
      if (
        args?.action?.type === 'invalidate' &&
        args.query.queryKey[0] === 'user'
      ) {
        setUserData(queryClient.getQueryData<User>(['user']) ?? null)
      }
    },
    [queryClient]
  )

  useEffect(() => {
    unsubscribeFnRef.current = queryCache.subscribe(updateUserData)

    return () => {
      if (unsubscribeFnRef.current) {
        unsubscribeFnRef.current()
      }
    }
  }, [updateUserData, queryCache])

  return userData
}

export default useUser
