import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileAndTabletCSS } from '@measures/responsive'

interface LabelProps {
  labelColor: string | undefined
  hasBracket: boolean
  children: ReactNode
}

type StyledLabelProps = Pick<LabelProps, 'labelColor' | 'hasBracket'>

const StyledLabel = styled.div<StyledLabelProps>`
  ${({
    theme: {
      spacing: { spacing4 },
      color: {
        primary: { primary02: primary02Color, blickRed },
      },
      typography: {
        eyebrows: {
          small2: { bundledCSS: small2EyebrowCSS },
        },
      },
      utils: { ellipsis },
    },
    labelColor,
    hasBracket,
  }) => css`
    box-sizing: border-box;
    ${ellipsis('100%', 1)};
    display: inline-block;
    ${small2EyebrowCSS};
    color: ${primary02Color};
    background-color: ${labelColor ?? blickRed};
    padding: 0.28em 0.4em 0.2em;
    margin-bottom: ${spacing4};
    text-transform: uppercase;
    ${mobileAndTabletCSS(css`
      ${hasBracket &&
      css`
        padding: 0 0 0.2em;
      `}
    `)}

    &:empty {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;

      &:before {
        content: '​';
        width: 0;
      }
    }
  `}
`

const Label: FunctionComponent<LabelProps> = ({
  labelColor,
  children,
  hasBracket,
}) => {
  return (
    <StyledLabel hasBracket={hasBracket} labelColor={labelColor}>
      {children}
    </StyledLabel>
  )
}

export default Label
