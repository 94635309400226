import { ConfigProps } from '@config/types'

const config = ({}: ConfigProps) =>
  ({
    organizationId: 'https://www.blick.ch/#/schema/Organization/1',
    organizationSchemaUrl: 'https://www.blick.ch/#/schema/Organization/',
    parentOrganizationId: 'https://www.blick.ch/#/schema/Organization/2',
    organizationAddressId:
      'https://www.blick.ch#/schema/Address/Organization/1',
    breadcrumbListSchemaUrl: 'https://www.blick.ch/#/schema/BreadcrumbList',
    webSiteId: 'https://www.blick.ch/#/schema/WebSite/1',
    urlTemplate: 'https://www.blick.ch/suche/?q=Schweiz',
    iosUrl: 'https://apps.apple.com/ch/app/blick-news-sport/id375173494',
    androidUrl: 'https://play.google.com/store/apps/details?id=ch.blick.news',
    videoSchemaUrl: 'https://www.blick.ch/#/schema/VideoObject/',
    personSchemaUrl: 'https://www.blick.ch/#/schema/Person',
    articleSchemaUrl: 'https://www.blick.ch/#/schema/Article/',
    liveBlogPostingUrl: 'https://www.blick.ch/#/schema/LiveBlogPosting/',
    blogPostingUrl: 'https://www.blick.ch/#/schema/BlogPosting/',
    educationalOccupationalCredentialSchemaUrl:
      'https://www.blick.ch/#/schema/EducationalOccupationalCredential/',
  }) as const satisfies Record<string, unknown>

export default config
