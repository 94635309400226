import { CookWidget, JSONTypeForCookWidget } from '@widgets/types'
import { FunctionComponent, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Image, Link, TeaserVideo, TargetContentType } from '@utils/cook/types'
import VideoLabel from '@components/VideoLabel'
import Catchword from '@components/TeaserStandardVerticalVideo/Catchword'
import Title from '@components/TeaserStandardVerticalVideo/Title'
import TeaserImage from '@components/TeaserStandardVerticalVideo/TeaserImage'
import BlickPlusLogo from '@components/TeaserStandardVerticalVideo/BlickPlusLogo'
import Blink from '@components/Blink'
import TeaserAnimatedPreview from '@components/TeaserAnimatedPreview'
import useAnimatedPreview from '@hooks/useAnimatedPreview'
import { desktopCSS } from '@measures/responsive'
import useRefDelegate from '@hooks/useRefDelegate'
import useViewportTracking from '@hooks/useViewportTracking'
import useTracking, { TrackingFnType } from '@hooks/useTracking'
import { triggerAureusImpression } from '@utils/aureus'
import { useQueryClient } from '@tanstack/react-query'
import { stripHtml } from '@hooks/useTracking/utils'
import useShowRedCatchwordVariant from '@hooks/useShowRedCatchwordVariant'

export interface TeaserStandardVerticalVideoAPIProps {
  title: string
  catchword?: string
  image: Image<'16_9'>
  link: Link
  video: TeaserVideo
  targetContentType: TargetContentType
  isPlus?: boolean
  track?: boolean
  articleId: string
  aureusOfferId?: string
}

const StyledTeaserStardardVerticalVideo = styled(Blink)`
  display: block;
  text-decoration: none;
  appearance: none;
`

const StyledVideoLabel = styled(VideoLabel)`
  ${({
    theme: {
      spacing: { spacing8 },
    },
  }) => css`
    position: absolute;
    z-index: 1;
    bottom: ${spacing8};
    left: ${spacing8};
  `}
`

const StyledTeaserImage = styled(TeaserImage)`
  ${({
    theme: {
      spacing: { spacing8, spacing16 },
    },
  }) => css`
    margin-bottom: ${spacing8};

    ${desktopCSS(css`
      margin-bottom: ${spacing16};
    `)}
  `}
`
const TeaserStandardVerticalVideo: FunctionComponent<
  TeaserStandardVerticalVideoAPIProps
> = ({
  articleId,
  catchword,
  image,
  isPlus,
  link,
  targetContentType,
  title,
  video,
  aureusOfferId,
  track,
}) => {
  const showCatchword = !!isPlus || !!catchword
  const showRedCatchwordsVariant = useShowRedCatchwordVariant()
  const isABtest = !!catchword
  const shouldBeTracked = !!track || isABtest
  const queryClient = useQueryClient()

  const onImpression = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_impression',
      eventCategory: 'video_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'impression',
      link_url: link.href,
      teaser_type: showRedCatchwordsVariant ? 'variantB' : 'original',
    }),
    [articleId, title, link.href, showRedCatchwordsVariant]
  )

  const onClick = useCallback<TrackingFnType>(
    () => ({
      event: 'teaser_click',
      eventCategory: 'video_teaser',
      eventLabel: `${articleId}:${stripHtml(title)}`,
      eventAction: 'click',
      id: articleId,
      link_url: link.href,
      teaser_type: showRedCatchwordsVariant ? 'variantB' : 'original',
    }),
    [articleId, title, link.href, showRedCatchwordsVariant]
  )

  const trackedOnImpression = useTracking(onImpression)
  const trackedOnClick = useTracking(onClick)

  const finalOnImpression = useCallback(() => {
    triggerAureusImpression({
      aureusOfferId,
      queryClient,
    })

    if (shouldBeTracked) {
      trackedOnImpression()
    }
  }, [aureusOfferId, shouldBeTracked, trackedOnImpression, queryClient])

  const clickableProps = {
    aureusOfferId,
    ...(shouldBeTracked ? { onClick: trackedOnClick } : {}),
    ...link,
  }

  const ref = useRefDelegate(
    useAnimatedPreview({
      video,
      articleUrl: clickableProps.href,
    }),
    useViewportTracking({
      onImpression: finalOnImpression,
      track: true,
    })
  )

  return (
    <StyledTeaserStardardVerticalVideo {...clickableProps} ref={ref}>
      <StyledTeaserImage {...image}>
        {!!video && (
          <TeaserAnimatedPreview video={video} articleUrl={link?.href} />
        )}
        <StyledVideoLabel
          duration={video.duration}
          targetContentType={targetContentType}
        />
      </StyledTeaserImage>
      {showCatchword && (
        <Catchword>
          {isPlus && <BlickPlusLogo />}
          {catchword}
        </Catchword>
      )}
      <Title>{title}</Title>
    </StyledTeaserStardardVerticalVideo>
  )
}

const widget = {
  kind: ['teaser-standard-vertical-video'],
  component: TeaserStandardVerticalVideo,
} as const satisfies CookWidget

export type WidgetType = typeof widget

export type JSONWidgetType = JSONTypeForCookWidget<WidgetType>

export default widget
