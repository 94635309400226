import { Association } from '@components/Schema/types'

const getPreparedAssociations = (associations?: Association[]) =>
  associations?.map((association) => {
    const {
      organizationKnowledgeGraphId,
      organizationAlternateName,
      organizationUrl,
      organizationWikipedia,
      organizationName,
      organizationId,
    } = association
    const sameAs = [
      ...(organizationKnowledgeGraphId
        ? [
            `https://www.google.com/search?kgmid=/g/${organizationKnowledgeGraphId}`,
          ]
        : []),
      ...(Array.isArray(organizationWikipedia)
        ? organizationWikipedia
        : organizationWikipedia
          ? [organizationWikipedia]
          : []),
    ]
    return {
      id: organizationId || organizationName || '',
      name: organizationName,
      url: organizationUrl,
      alternateName: organizationAlternateName,
      sameAs: sameAs,
    }
  })

export { getPreparedAssociations }
