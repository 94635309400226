import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import useShowRedCatchwordVariant from '@hooks/useShowRedCatchwordVariant'

export interface CatchwordProps {
  children?: ReactNode
}

interface StyledCatchwordProps {
  showRedCatchwordsVariant: boolean
}

const StyledCatchword = styled.div<StyledCatchwordProps>`
  ${({
    theme: {
      color: {
        tertiary: { grey400: grey400Color },
        primary: { blickRed },
      },
      typography: {
        subheads: {
          small1: { bundledCSS: small1SubheadingCSS },
        },
      },
      spacing: { spacing4 },
      utils: { ellipsis },
    },
    showRedCatchwordsVariant,
  }) => css`
    ${ellipsis('100%', 1)};
    ${small1SubheadingCSS};
    color: ${showRedCatchwordsVariant ? blickRed : grey400Color};
    margin-bottom: ${spacing4};
  `}
`

const Catchword: FunctionComponent<CatchwordProps> = ({ children }) => {
  const showRedCatchwordsVariant = useShowRedCatchwordVariant()
  return (
    <StyledCatchword showRedCatchwordsVariant={showRedCatchwordsVariant}>
      {children}
    </StyledCatchword>
  )
}

export default Catchword
