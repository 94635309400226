import useABTestValue from '@hooks/useABTestValue'

export type UseBracketInUltraModule = () => ReturnType<
  typeof useABTestValue<'showHalfAndTopBracketsInHorizontalTeasers'>
>

const useBracketInUltraModule: UseBracketInUltraModule = () =>
  useABTestValue('showHalfAndTopBracketsInHorizontalTeasers')

export default useBracketInUltraModule
