;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"next-prod-3.1.48-blick"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { Options } from '@sentry/types'
import type * as clientSdk from '@sentry/nextjs/build/types/client'
import config from '@config'

const {
  sentry: { DSN, debugModeEnabled },
  backend: { teamcityEnv, blickReleaseVersion },
} = config

const getIsAdBlockerEnabled = (): boolean => {
  let adBlockerCookieValue: boolean | undefined

  try {
    const cookie = document.cookie
      .split(';')
      .find((cookie) => cookie.includes('__adblocker'))

    if (cookie) {
      const splitCookie = cookie.split('=')
      if (splitCookie.length === 2 && splitCookie[1]) {
        adBlockerCookieValue = splitCookie[1] === 'true'
      }
    }
  } catch {
    // we might get an error when cookies are disabled
  }

  return !!adBlockerCookieValue
}

const sentryConfig: Options | clientSdk.BrowserOptions = {
  debug: debugModeEnabled,
  dsn: DSN,
  environment: teamcityEnv,
  sampleRate: teamcityEnv === 'dev' ? (debugModeEnabled ? 1 : 0) : 0.1,
  release: blickReleaseVersion,
  integrations: function (integrations) {
    return integrations.filter(function (integration) {
      return ![
        debugModeEnabled ? 'InboundFilters' : '',
        'Breadcrumbs',
        'BrowserApiErrors',
        'BrowserTracing',
        'LinkedErrors',
        'GlobalHandlers',
      ].includes(integration.name)
    })
  },
  beforeSend: function (event, hint) {
    if (getIsAdBlockerEnabled()) {
      return null
    }

    if (debugModeEnabled) {
      console.log('SENTRY: event', '\nevent\n', event, 'hint', hint)
      return null
    }

    return event
  },
  beforeSendTransaction: function (event, hint) {
    if (getIsAdBlockerEnabled()) {
      return null
    }

    if (debugModeEnabled) {
      console.log('SENTRY: transaction', '\nevent\n', event, '\nhint\n', hint)
      return null
    }

    return event
  },
  allowUrls: [
    //Localhost addresses
    /^http:\/\/localhost.*/,
    /^http:\/\/local.blick.ch.*/,

    // Blick & Ringier
    /^https:\/\/[a-zA-Z0-9\.-]+\.blick\.ch.*/,
    /^https:\/\/[a-zA-Z0-9\.-]+\.ringier\.ch.*/,

    // JWPlayer
    /^https:\/\/ssl\.p\.jwpcdn\.com.*/,
    /^https:\/\/cdn\.jwplayer\.com.*/,
    /^https:\/\/prd\.jwpltx\.com.*/,
    /^https:\/\/assets-jpcust\.jwpsrv\.com.*/,

    //CMP
    /^https:\/\/cdn\.cookielaw\.org.*/,
  ],
  ignoreErrors: [
    'The play() request was interrupted',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Suspense boundary',
    'Blocked a restricted frame',
    'attempted to hard navigate to the same URL',
    "Can't find variable: IntersectionObserver",
    "Can't find variable: ResizeObserver",
    "ReferenceError: Can't find variable: setNptTechAdblockerCookie",
    'ReferenceError: setNptTechAdblockerCookie is not defined',
    'TypeError: null is not an object (evaluating \'document.getElementById("gm_sitebar_container").style\')',
    "ReferenceError: Can't find variable: enableDarkTheme",
    "TypeError: Cannot read properties of null (reading 'off')",
    'TypeError: this._resizeCallback is not a function',
    'TypeError: Failed to fetch',
    'Error: Hydration failed because the initial UI does not match what was rendered on the server.',
    'UnhandledRejection: Non-Error promise rejection captured with value: undefined',
    'Error: There was an error while hydrating. Because the error happened outside of a Suspense boundary',
    'SecurityError: Blocked a frame with origin "https://www.blick.ch" from accessing a cross-origin frame.',
    'Error: Text content does not match server-rendered HTML.',
    'TypeError: can\'t redefine non-configurable property "userAgent"',
    "ReferenceError: Can't find variable: logMutedMessage",
    'TypeError: this.instream_ is null',
    "TypeError: null is not an object (evaluating 'v.trigger')",
    "TypeError: Cannot read properties of null (reading 'cancel')",
    "TypeError: null is not an object (evaluating 'k.set')",
    "TypeError: null is not an object (evaluating 'I.cancel')",
    "SyntaxError: Identifier 'change_ua' has already been declared",
    'ReferenceError: Sailthru is not defined',
    'Error: Invariant: attempted to hard navigate to the same URL',
    "SyntaxError: Document.querySelectorAll: 'div:has(> iframe",
    'NotAllowedError: The request is not allowed by the user agent or the platform in the current context',
    "TypeError: Cannot read properties of null (reading 'frames')",
    "TypeError: Cannot read property 'frames' of null",
    "TypeError: null is not an object (evaluating 'currentParentWindow.frames')",
  ],
} as const satisfies Parameters<typeof Sentry.init>[0]

Sentry.init(sentryConfig)
