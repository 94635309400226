const ORGANIZATIONS = [
  {
    organizationId: '11f08b7b0h',
    organizationName: 'Verband Schweizer Medien',
    organizationUrl: 'https://www.schweizermedien.ch/',
    organizationKnowledgeGraphId: '11f08b7b0h',
    organizationWikipedia:
      'https://de.wikipedia.org/wiki/Verband_Schweizer_Medien',
  },
  {
    organizationId: '11fhql5vgx',
    organizationName: 'Verein GS1 Schweiz',
    organizationUrl: 'https://www.gs1.ch/de',
    organizationKnowledgeGraphId: '11fhql5vgx',
  },
  {
    organizationId: '11s232rqwb',
    organizationName: 'MVFP Medienverband der freien Presse',
    organizationUrl: 'https://www.mvfp.de/',
    organizationKnowledgeGraphId: '11s232rqwb',
    organizationWikipedia:
      'https://de.wikipedia.org/wiki/MVFP_Medienverband_der_freien_Presse',
  },
  {
    organizationId: '120l2j70',
    organizationName: 'WEMF AG für Werbemedienforschung',
    organizationUrl: 'https://wemf.ch/',
    organizationKnowledgeGraphId: '120l2j70',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/WEMF',
      'https://en.wikipedia.org/wiki/WEMF_AG_f%C3%BCr_Werbemedienforschung',
    ],
  },
  {
    organizationId: '120z_3f9',
    organizationName: 'Börsenverein des Deutschen Buchhandels',
    organizationUrl: 'https://www.boersenverein.de/',
    organizationKnowledgeGraphId: '120z_3f9',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/B%C3%B6rsenverein_des_Deutschen_Buchhandels',
      'https://en.wikipedia.org/wiki/B%C3%B6rsenverein_des_Deutschen_Buchhandels',
    ],
  },
  {
    organizationId: '1q6fvtgt4',
    organizationName: 'Handelskammer Deutschland-Schweiz',
    organizationUrl: 'https://www.handelskammer-d-ch.ch/de',
    organizationKnowledgeGraphId: '1q6fvtgt4',
    organizationWikipedia:
      'https://de.wikipedia.org/wiki/Handelskammer_Deutschland-Schweiz',
  },
  {
    organizationId: '0174w5',
    organizationName: 'World Economic Forum',
    organizationUrl: 'https://www.weforum.org/',
    organizationKnowledgeGraphId: '0174w5',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/World_Economic_Forum',
      'https://en.wikipedia.org/wiki/World_Economic_Forum',
    ],
  },
  {
    organizationId: '02ln61',
    organizationName: 'Reporters sans frontières',
    organizationUrl: 'https://rsf.org/fr',
    organizationKnowledgeGraphId: '02ln61',
    organizationWikipedia: [
      'https://fr.wikipedia.org/wiki/Reporters_sans_fronti%C3%A8res',
      'https://de.wikipedia.org/wiki/Reporter_ohne_Grenzen',
      'https://en.wikipedia.org/wiki/Reporters_Without_Borders',
    ],
  },
  {
    organizationId: '02p9x00',
    organizationName: 'INMA',
    organizationUrl: 'https://www.inma.org/',
    organizationKnowledgeGraphId: '02p9x00',
  },
  {
    organizationId: '0dpl5n',
    organizationName: 'WAN-IFRA',
    organizationUrl: 'https://wan-ifra.org/',
    organizationKnowledgeGraphId: '0dpl5n',
    organizationWikipedia: [
      'https://de.wikipedia.org/wiki/WAN-IFRA',
      'https://en.wikipedia.org/wiki/World_Association_of_Newspapers_and_News_Publishers',
    ],
  },
]
export { ORGANIZATIONS }
