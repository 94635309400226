import { FunctionComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { mobileAndTabletCSS, mobileCSS, tabletCSS } from '@measures/responsive'

interface BracketWrapperProps {
  children: ReactNode
  shouldShowTopBracket?: boolean
  shouldShowHalfBracket?: boolean
  labelColor?: string
}

const BracketWrapper = styled.div<BracketWrapperProps>`
  ${({
    theme: {
      color: {
        primary: { blickRed },
      },
      measures: { stretchBackground },
      spacing: { spacing16 },
    },
    shouldShowTopBracket,
    shouldShowHalfBracket,
    labelColor,
  }) => {
    const topBracketCSS = css`
      position: relative;
      border-top: 34px solid ${labelColor || blickRed};
      ${mobileCSS(css`
        ${stretchBackground()};
      `)}
      ${tabletCSS(css`
        padding: 0 ${spacing16};
        margin: 0 -${spacing16};
      `)}
    `
    return css`
      ${mobileAndTabletCSS(css`
        ${shouldShowTopBracket && topBracketCSS};
        ${shouldShowHalfBracket &&
        css`
          ${topBracketCSS};

          &:before {
            content: '';
            position: absolute;
            z-index: 1;
            background-color: ${labelColor || blickRed};
            left: 0;
            top: -1px;
            width: ${spacing16};
            height: calc(140px - 32px - 1px);
          }
        `}
      `)}
    `
  }}
`

const Bracket: FunctionComponent<BracketWrapperProps> = ({
  children,
  shouldShowHalfBracket,
  shouldShowTopBracket,
  labelColor,
}) => {
  return (
    <BracketWrapper
      labelColor={labelColor}
      shouldShowHalfBracket={shouldShowHalfBracket}
      shouldShowTopBracket={shouldShowTopBracket}>
      {children}
    </BracketWrapper>
  )
}

export default Bracket
